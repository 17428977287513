import React, { useState } from 'react';
import { graphql } from 'gatsby';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import SimplePortableText from '@components/sanity/simplePortableText';
import Layout from '@components/layout';
import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';
import { motion, AnimatePresence } from 'framer-motion';

import Chevron from '@svg/vector.svg';

const AccordianItem = ({
  title,
  content,
  active,
  setActive,
  _key,
  index,
  setActiveTab,
}) => {
  const isActive = active === index;
  const handleClick = () => {
    !isActive ? setActive(index) : setActive(null);
    setActiveTab(index);
  };
  return (
    <div className='text-black'>
      <div
        className='flex  justify-between items-center cursor-pointer border-b border-grey border-opacity-50 pb-4 space-x-4'
        onClick={handleClick}
        role='button'>
        <h1 className='text-sm md:text-base'>{title}</h1>
        <motion.div animate={{ rotate: !isActive ? 90 : 270 }}>
          <Chevron className='w-[30px] md:w-[53px]  svg-green' />
        </motion.div>
      </div>

      <div className=' w-full h-[2px] pb-8'></div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className='overflow-hidden'
            transition={{
              duration: 0.5,
            }}>
            <SimplePortableText text={content} />
            <div className=' w-full h-16'></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const LegalPage = ({ data: { page } }) => {
  const { items, seo } = page || {};
  const [active, setActive] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />

      <div className='px-gutter pt-52 xl:pt-96 bg-green-tint'>
        <h1 className='text-blue richH0 mb-16'>Legal</h1>
        {/* <SimplePortableText text={pageText} /> */}
        {items?.map((item, i) => (
          <AccordianItem
            {...item}
            active={active}
            setActive={setActive}
            setActiveTab={setActiveTab}
            index={i}
          />
        ))}
      </div>
      <ExploreMenu />
    </Layout>
  );
};

export default LegalPage;

export const pageQuery = graphql`
  query legalPageQuery {
    page: sanityLegalPage {
      seo {
        ...SEO
      }
      title
      items {
        title
        content: _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
